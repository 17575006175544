import React from "react";
import theme from "../../../theme";
import { H1, P, SectionWrapper } from "../SectionStyled";
import MethodenBlocks from "./MethodenBlocks";
import {
  Block,
  Inner,
  Astrix,
  MobileBlock,
  ThemaBlock,
  ThemaBlockImage1,
  ThemaBlockImage2,
  OuterWrapper,
} from "./MethodenStyled";

const Methoden: React.FC = () => (
  <>
    <SectionWrapper>
      <H1>Auf Dich hören - Methoden</H1>
      <OuterWrapper>
        <ThemaBlockImage1 />
        <ThemaBlock>
          Jeder lebende Organismus versucht, den für sich besten inneren Zustand
          zu erreichen. Dieses Prinzip der sogenannten Selbstaktualisierung geht
          auf den Psychologen Carl R. Rogers zurück, der mich umfangreich
          inspiriert hat. Sein Konzept der Gesprächspsychotherapie gehört zu den
          wissenschaftlich anerkannten Verfahren. Sie haben für Ihre Probleme
          eine herausragende innere Lösungskompetenz, der wir zuhören und die
          Sie aktivieren. Ich begleite Sie authentisch, wertschätzend und
          lösungsorientiert.
        </ThemaBlock>
      </OuterWrapper>
      <OuterWrapper>
        <ThemaBlock>
          <b>Tinnitus</b> – Unser Hörsystem ist eines der feinfühligsten
          Wahrnehmungs- und Kommunikationssysteme, die wir haben. Ihr
          Ohrgeräusch entsteht in der Regel nicht im Ohr selbst. Es ist nach
          heutigem Kenntnisstand oft eine Antwort des Systems auf einen
          Hörverlust oder auf eine Überlastung der Hörfilter. Falls ein
          fachgerechter Hörtest keine Einschränkungen zeigt, hören Sie unter
          Umständen Ihren eigenen Stress oder andere psychische Alarmrufe. Durch
          die gemeinsame hypnotherapeutische Arbeit eröffnen wir den Weg zur
          Veränderung. Und zu einem Hören ohne Leiden.
        </ThemaBlock>
        <ThemaBlockImage2 />
      </OuterWrapper>
    </SectionWrapper>
    <SectionWrapper>
      <MobileBlock />
      <Block color={theme.secondary}>
        <Inner>
          <ul>
            <li>
              <P>Umfangreiche persönliche Gesamtbetrachtung und Analyse</P>
            </li>
            <li>
              <P>Coaching Lebenssituation, systemisches Coaching</P>
            </li>
            <li>
              <P>Hypnotherapie/Hypnose nach M. Erickson</P>
            </li>
            <li>
              <P>Klientenzentrierte Gesprächstherapie nach C. Rogers</P>
            </li>
            <li>
              <P>Ressourcenorientierte Psychotherapie</P>
            </li>
          </ul>
          <Astrix>
            auf Grundlage des aktuellen Kenntnisstands von Wissenschaft und
            Forschung
          </Astrix>
        </Inner>
      </Block>
    </SectionWrapper>
    <MethodenBlocks />
  </>
);

export default Methoden;

import styled from "styled-components";

export const Wrapper = styled.footer`
  width: 100vw;
  nav {
    background: #505559;
    padding: 30px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 700px
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }

  a {
    padding: 5px 20px;
    width: 165px;
    text-align: center;
    font-size: 14px;
    text-decoration: none;
    color: white;
    text-transform: uppercase;
  }
`;

export const Inner = styled.div`
  background: white;
  margin: 0 auto;
  padding: 30px 50px;
  max-width: 1000px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  img {
    width: 200px;
  }

  @media(max-width: 770px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const Block = styled.div`
  color: ${({ theme }) => theme.primary};
  font-size: 14px;
  padding: 10px 0;
`;

export const BlockLabel = styled.label`
  font-weight: bold;
  font-family: 'NotoSansBold';
  color: black;
`;

import React from "react";
import { MENU_ITEMS } from "../Header/Header";
import { ListItem } from "../Header/HeaderStyled";
import { Wrapper, CloseButton } from "./SideMenuStyled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import theme from "../../theme";

const SideMenu: React.FC<{
  closeSideNav: () => void;
  onItemClick: (id: string) => void;
}> = ({ closeSideNav, onItemClick }) => (
  <Wrapper id="side-nav">
    <CloseButton onClick={closeSideNav}>
      <FontAwesomeIcon color={theme.primary} icon={faTimes} size="lg" />
    </CloseButton>
    <ul>
      {MENU_ITEMS.map((i) => (
        <ListItem key={i.text} onClick={() => onItemClick(i.id)}>
          {i.text}
        </ListItem>
      ))}
    </ul>
  </Wrapper>
);

export default SideMenu;

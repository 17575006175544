import React from "react";
import { SectionWrapper, H1, P } from "../SectionStyled";
import { BlocksWrapper, Block, BlockImage } from "./UberMichStyled";
import theme from "../../../theme";

const UberMich: React.FC = () => (
  <>
    <SectionWrapper>
      <H1>Über mich</H1>
      <P>
        Ich habe als Diplom-Volkswirt zunächst lange und erfolgreich als
        Moderator, Redakteur und Reporter in TV-Journalismus gearbeitet. 2018
        erlitt ich im Rahmen einer Erkrankung eine Hörschädigung mit dauerhafter
        einseitiger Ertaubung und schwerem Tinnitus, die mir die Fortsetzung
        mehrerer Tätigkeitsbereiche unmöglich machte. Seitdem habe ich mich mit
        meinen Ohren und meiner Gesundheit intensiv beschäftigt, selber
        verschiedene Therapien durchlaufen, Erfolge erzielt, mich auf dem
        therapeutischen Sektor umfangreich fortgebildet und mich beruflich neu
        orientiert.
      </P>
      <P>
        Insbesondere die Hypnotherapie, also das Ansprechen des Unbewussten,
        eröffnet neue Möglichkeiten, um die Bewertung der Symptome zu ändern,
        die Aufmerksamkeit neu zu fokussieren, und Gelassenheit sowie Vertrauen
        zum eigenen Körper wiederzufinden.
      </P>
      <P>
        Daher lege ich auf die Hypnose einen besonderen Fokus. Zu den weiteren
        wissenschaftlich fundierten Methoden, mit denen ich arbeite, gehören
        neben der Hypnose die ressourcenorientierte Psychotherapie, die
        klientenzentrierte Gesprächstherapie und das systemische Coaching.
      </P>
      <P>
        Ich bin Mitglied der
        <b>
          <a href="https://www.tinnitus-liga.de">
            Deutschen Tinnitus-Liga e.V.
          </a>
        </b>{" "}
        und gehöre zu den dort gelisteten{" "}
        <b>
          <a href="https://www.tinnitus-liga.de/pages/premium-bereich-fuer-mitglieder/dtl-fachleute.php">
            Fachleuten und Therapeuten der Deutschen Tinnitus-Liga.
          </a>
        </b>
      </P>
    </SectionWrapper>
    <SectionWrapper>
      <BlocksWrapper>
        <BlockImage />
        <Block color={theme.primary}>
          <H1>Ausbildung</H1>
          <ul>
            <li>
              <P>Heilpraktiker für Psychotherapie</P>
            </li>
            <li>
              <P>Hypnotherapeut nach NGH (National Guild of Hypnotists), NLP</P>
            </li>
            <li>
              <P>Ego-State Therapie und Coaching (Neures-Akademie)</P>
            </li>
            <li>
              <P>Coaching und Persönlichkeitsentwicklung (Arche Medica)</P>
            </li>
            <li>
              <P>Ressourcenorientierte Psychotherapie (Arche Medica)</P>
            </li>
          </ul>
        </Block>
      </BlocksWrapper>
    </SectionWrapper>
  </>
);

export default UberMich;

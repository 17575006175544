import styled from "styled-components";
import { HEADER_HEIGHT } from ".";

export const BurgerMenu = styled.div`
  display: none;
  cursor: pointer;

  @media (max-width: 1120px) {
    display: flex;
    align-items: center;
  }
`;

export const Label = styled.label`
  font-size: 11px;
  margin: 0 30px;
`;

export const LabelDesktop = styled(Label)`
  display: block;

  @media (max-width: 1120px) {
    display: none;
  }
`;

export const LabelMobile = styled(Label)`
  display: none;

  @media (max-width: 1120px) {
    display: block;
  }
`;

export const Wrapper = styled.div`
  z-index: 1000;
  background: white;
  position: fixed;
  height: ${HEADER_HEIGHT}px;
  width: 100%;
  box-shadow: 0px 1px 3px 0px rgb(0 0 0 / 25%);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px;

  + main {
    padding-top: ${HEADER_HEIGHT}px;
    min-height: calc(100vh - ${HEADER_HEIGHT - 4}px);
  }

  a {
    line-height: 0;
  }

  ul {
    display: flex;

    li {
      margin-left: 30px;
    }
  }

  @media (max-width: 1120px) {
    display: flex;
  }

  .logo {
    height: ${HEADER_HEIGHT}px;
    padding: 10px 0;
  }
`;

export const Nav = styled.nav`
  display: flex;
  align-items: center;
  ul,
  li {
    margin: 0;
    display: flex;
    align-items: center;
  }
  @media (max-width: 1120px) {
    display: none;
  }
`;

export const ListItem = styled.li`
  list-style-type: none;
  text-transform: uppercase;
  font-size: 13px;
  text-decoration: none;
  color: black;
  cursor: pointer;
`;

import styled from "styled-components";
// @ts-ignore
import praxis from "../../../images/praxis.jpeg";

export const OuterWrapper = styled.div`
  display: flex;
  justify-content: center;
  max-width: 900px;
  margin: 5px auto;
  margin-top: 30px;

  @media (max-width: 790px) {
    flex-direction: column;
    padding: 0 30px;
  }
`;

export const ThemaBlock = styled.div`
  margin: 5px;
  width: 50%;
  height: 400px;
  font-size: 16px;

  iframe {
    width: 100%;
    height: 400px;
  }

  @media (max-width: 790px) {
    margin: 5px 0;
    width: 100%;
  }
`;

const ImageBlock = styled(ThemaBlock)`
  background-size: cover;
  background-position: center;
`;

export const ThemaBlockImage1 = styled(ImageBlock)`
  background-image: ${`url(${praxis})`};
`;

import React from "react";
import theme from "../../../theme";
import { SectionWrapper, P, H2 } from "../SectionStyled";
import { MethodsBlockWrapper, MethodBlock } from "./MethodenStyled";

const MethodenBlocks: React.FC = () => (
  <SectionWrapper>
    <MethodsBlockWrapper>
      <MethodBlock background={theme.secondary}>
        <H2>
          Ressourcenorientierte <br />
          Psychotherapie
        </H2>
        <P>
          Wir benennen und beschreiben das Problemfeld und die weiteren
          psychischen oder körperlichen Belastungen, ggf. das Ohrgeräusch, und
          fokussieren dann bei den Lösungsansätzen auf Ihre schon vorhandenen
          Ressourcen: die Kraftquellen und Mittel, die Sie haben und kennen, in
          Ihrem Inneren und Ihrem Umfeld, und die Ihnen helfen,
          Handlungs-Optionen zu erkennen, anzuwenden und Wege zur Besserung
          einzuschlagen. Die entscheidenden Potenziale liegen in Ihnen. Wir
          legen sie frei und helfen Ihnen, gut auf sich selbst zu hören.
        </P>
      </MethodBlock>
      <MethodBlock background={theme.tertiary}>
        <H2>
          Hypnotherapie <br />
          Hypnose
        </H2>
        <P>
          Ihr Verstand und Ihr Bewusstsein haben sich ein ausgiebiges Bild von
          Ihnen und Ihrer Lage gemacht. Und suchen weiter nach Erklärungen und
          Bestätigungen, ohne unbedingt adäquate Lösungen anzubieten. Die
          wesentlichen emotionalen Bewertungen und Entscheidungen trifft
          hingegen Ihr limbisches System, Ihr Unterbewusstes. Wir öffnen den
          Zugang zu ihm und hören auf seine vielfältigen und tiefgreifenden
          Lösungsideen. Die Hypnose ist ein wissenschaftlich anerkanntes und
          fundiertes Verfahren, um wirksame Veränderungen im Denken, Fühlen und
          Verhalten zu ermöglichen. Und im Hören.
        </P>
      </MethodBlock>
    </MethodsBlockWrapper>
    <MethodsBlockWrapper>
      <MethodBlock background={theme.tertiary}>
        <H2>
          Systemisches
          <br />
          Coaching
        </H2>
        <P>
          Kaum ein Problem im Bereich von Persönlichkeit, Geist, Seele und
          Gesundheit ist monokausal und folgt einem simplen
          Ursache-Wirkung-Schema. Wir versuchen, den Blick zu weiten, und
          betrachten auch die Systeme, die Sie umgeben und deren Teil Sie sind,
          mit all ihren Wechselwirkungen. Auch in Ihnen selbst ist
          Vielstimmigkeit, ein inneres Team, mit verschiedenen Wünschen und
          Bedürfnissen, in dem alle Stimmen gehört werden möchten. Wir hören
          hin.
        </P>
      </MethodBlock>
      <MethodBlock background={theme.secondary}>
        <H2>
          Klientenzentrierte
          <br />
          Gesprächstherapie
        </H2>
        <P>
          In unserer gemeinsamen Arbeit geht es um Sie. Es geht um Ihr Problem
          und Ihre Veränderungswünsche. Und die Lösungen und Verbesserungen
          finden letztlich auch Sie. Ich begleite Sie kompetent und helfend auf
          diesem Weg, ohne Sie etwa zu Schritten zu drängen, die nicht Ihre
          eigenen Schritte sind oder nicht zu Ihnen passen. Das gilt
          insbesondere auch für die Hypnose. Ich bin Ihr Partner auf Ihrem Weg,
          auf Augenhöhe. Ich begegne Ihnen authentisch, mit Mitgefühl, Respekt
          und Wertschätzung. Und höre Ihnen zu.
        </P>
      </MethodBlock>
    </MethodsBlockWrapper>
  </SectionWrapper>
);

export default MethodenBlocks;

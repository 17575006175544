import React from "react";
import { SectionWrapper, H1, P } from "../SectionStyled";

const Thema: React.FC = () => (
  <>
    <SectionWrapper>
      <H1>Ziele</H1>
      <P>
        Kraftvoll, gesund und zuversichtlich durchs Leben gehen. Die eigene
        Kompetenz erleben. Einen Sinn erkennen. Sich sicher und geborgen fühlen.
        Verbundenheit und Wertschätzung spüren. Sich entfalten und wachsen.
        <br />
        Diese Wünsche sind in uns allen – und die Fähigkeiten dazu auch.
        <br />
        Bei Stress und Druck, Niedergeschlagenheit und Ausgebranntheit, und bei
        Leiden an körperlichen Symptomen und Ängsten fehlen Ihnen möglicherweise
        Antworten und Konzepte.
        <br />
        Ich helfe Ihnen und begleite Sie dabei, wieder Perspektiven zu sehen,
        Kraft zu schöpfen, Lösungen für sich erkennen und mit Zuversicht aktiv
        zu werden.
      </P>
      <H1>Themen</H1>
      <P>
        Stress, Burnout, leichte Depressionen, Ängste, sowie körperliche
        Symptome, die zusammen mit diesen Belastungen auftreten, sind die
        Themen, die wir mit meinen modernen therapeutischen Methoden erfolgreich
        behandeln können. Ebenso gilt das für allgemeine oder spezielle
        Lebenskrisen. Sofern keine Therapie indiziert und notwendig ist, biete
        ich zu Ihrem persönlichen Thema einen Coaching-Prozess an.
      </P>
    </SectionWrapper>
  </>
);

export default Thema;

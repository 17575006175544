import { Link } from "gatsby";
import React from "react";
import { Block, Inner, Wrapper, BlockLabel } from "./FooterStyled";
// @ts-ignore
import logo from "../../images/logo.svg";

const Footer: React.FC = () => (
  <Wrapper>
    <Inner>
      <img className="footer-logo" src={logo} alt="logo" />
      <Block>
        <BlockLabel>Adresse</BlockLabel> <br />
        Sredzkistraße 64 <br />
        10405 Berlin - Prenzlauer Berg
      </Block>
      <Block>
        <BlockLabel>Kontakt</BlockLabel> <br />
        Email: praxis@aufdichhoeren.de <br />
        Telefon: +49 178 3143168
      </Block>
    </Inner>
    <nav>
      <Link to="/impressum">Impressum</Link>
      <Link to="/datenschutz">Datenschutz</Link>
      <Link to="/qualifikation">Qualifikation</Link>
      <Link to="/konditionen">Konditionen</Link>
    </nav>
  </Wrapper>
);

export default Footer;

import styled from "styled-components";
// @ts-ignore
import img from "../../../images/ubermich-cropped.jpg";

export const BlocksWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 0 auto;
  max-width: 900px;

  @media (max-width: 820px) {
    flex-direction: column;
  }
`;

export const Block = styled.div<{ color?: string; font?: string }>`
  padding: 30px;
  background: ${({ color }) => color};
  color: ${({ font }) => font ?? "white"};
  h1 {
    font-size: 34px;
    color: ${({ font }) => font ?? "white"};
    ::after {
      width: 50px;
      height: 2px;
      position: absolute;
      left: 0;
      bottom: 0;
      background-color: white;
      content: "";
    }
  }

  width: 50%;
  @media (max-width: 820px) {
    width: 100%;
    height: 100%;
  }
`;

export const BlockImage = styled(Block)`
  background-image: ${`url(${img})`};
  background-size: cover;
  background-position: center;
  @media (max-width: 820px) {
    height: 300px;
  }
`;

import React from "react";
import { SectionWrapper, H1, P } from "../SectionStyled";

const Tinnitus: React.FC = () => (
  <SectionWrapper>
    <H1>Tinnitus</H1>
    <P>
      Nach eigenem langen Tinnitus-Leiden und einseitiger Ertaubung habe ich
      mich zum Thema Tinnitus besonders intensiv fortgebildet und für dieses
      Symptom spezielle, weitreichende Kompetenzen entwickelt. Deshalb biete ich
      insbesondere bei diesem sehr individuellen und komplexen Problem meine
      Expertise, Hilfe und Therapie an.
    </P>
  </SectionWrapper>
);

export default Tinnitus;

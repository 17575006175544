import React from "react";
import {
  Wrapper,
  Nav,
  ListItem,
  BurgerMenu,
  LabelDesktop,
  LabelMobile,
} from "./HeaderStyled";
import SideMenu from "../SideMenu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import {
  Thema,
  UberMich,
  Appointment,
  Konditionen,
  Praxis,
  Methoden,
  Tinnitus,
} from "../Sections";
import theme from "../../theme/theme";
// @ts-ignore
import logo from "../../images/logo.svg";

export const MENU_ITEMS = [
  {
    text: "Ziele & Themen",
    id: "thema",
    component: Thema,
  },
  {
    text: "Tinnitus",
    id: "tinnitus",
    component: Tinnitus,
  },
  {
    text: "Methoden",
    id: "methoden",
    component: Methoden,
  },
  {
    text: "Über mich",
    id: "team",
    component: UberMich,
  },
  {
    text: "Preise & Konditionen",
    id: "konditionen",
    component: Konditionen,
  },
  {
    text: "Praxis",
    id: "praxis",
    component: Praxis,
  },
  {
    text: "Kontakt",
    id: "appointment",
    component: Appointment,
  },
];

const Header = () => {
  const openSideNav = () => {
    const sideNav = document.getElementById("side-nav");
    if (!sideNav) return;
    sideNav.style.left = "0";
  };

  const closeSideNav = () => {
    const sideNav = document.getElementById("side-nav");
    if (!sideNav) return;
    sideNav.style.left = "-250px";
  };

  const goTo = (id: string) => {
    const el = document.getElementById(id);
    if (!el) {
      window.location.href = `/#${id}`;
      return;
    }
    const headerOffset = 90;
    const elementPosition = el.getBoundingClientRect().top;
    const offsetPosition = elementPosition + window.pageYOffset - headerOffset;
    window.scrollTo({ top: offsetPosition, behavior: "smooth" });
  };

  const onMenuItemClick = (id: string) => {
    closeSideNav();
    goTo(id);
  };
  return (
    <Wrapper>
      <BurgerMenu onClick={openSideNav}>
        <FontAwesomeIcon color={theme.primary} icon={faBars} size="2x" />
      </BurgerMenu>
      <LabelMobile>
        Mitglied/Therapeut der Deutschen Tinnitus-Liga
      </LabelMobile>
      <a href="/">
        <img className="logo" src={logo} alt="logo" />
      </a>
      <LabelDesktop>
        Mitglied/Therapeut der <br /> Deutschen Tinnitus-Liga
      </LabelDesktop>
      <SideMenu onItemClick={onMenuItemClick} closeSideNav={closeSideNav} />
      <Nav>
        <ul>
          {MENU_ITEMS.map((i) => (
            <ListItem key={i.text} onClick={() => goTo(i.id)}>
              {i.text}
            </ListItem>
          ))}
        </ul>
      </Nav>
    </Wrapper>
  );
};

export default Header;

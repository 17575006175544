import styled from "styled-components";

export const SectionWrapper = styled.div`
  padding: 30px;
  padding-bottom: 0;
  padding-top: 50px;
`;

export const P = styled.p`
  font-size: 16px;
  margin: 0 auto;
  max-width: 700px;
  line-height: 1.5rem;
  :not(:last-of-type) {
    margin-bottom: 16px;
  }

  a {
    color: inherit;
  }
`;

export const H1 = styled.h1`
  position: relative;
  color: ${({ theme }) => theme.primary};
  margin: 0 auto;
  margin-bottom: 20px;
  max-width: 700px;
  padding-bottom: 10px;
  ::after {
    width: 50px;
    height: 2px;
    position: absolute;
    left: 0;
    bottom: 0;
    background-color: ${({ theme }) => theme.primary};
    content: "";
  }
`;

export const H2 = styled.h2`
  position: relative;
  margin: 0 auto;
  margin-bottom: 20px;

  ::after {
    width: 50px;
    height: 2px;
    position: absolute;
    left: 0;
    bottom: 0;
    background-color: ${({ theme }) => theme.primary};
    content: "";
  }
`;

import styled from "styled-components";
// @ts-ignore
import img from "../../../images/green.jpeg";
// @ts-ignore
import image1 from "../../../images/thema-1-cropped.jpg";
// @ts-ignore
import image2 from "../../../images/thema-2-cropped.jpg";

export const Inner = styled.div`
  padding: 40px;
  width: 50%;

  @media (max-width: 800px) {
    width: 100%;
    padding: 20px;
  }
`;

export const Block = styled.div`
  max-width: 900px;
  margin: 0 auto;
  background-image: ${`url(${img})`};
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: flex-end;
  color: white;
  h1 {
    font-size: 34px;
    color: white;
    ::after {
      width: 50px;
      height: 2px;
      position: absolute;
      left: 0;
      bottom: 0;
      background-color: white;
      content: "";
    }
  }

  p {
    font-weight: bold;
  }

  @media (max-width: 800px) {
    background-color: ${({ theme }) => theme.primary};
    background-image: none;
  }
`;

export const MobileBlock = styled.div`
  display: none;
  background-size: cover;
  background-position: center;
  height: 400px;

  @media (max-width: 800px) {
    display: block;
    background-image: ${`url(${img})`};
  }
`;

export const Astrix = styled.p`
  font-size: 14px;
  margin-top: 10px;
  color: white;
  margin-bottom: 0;
`;

export const MethodsBlockWrapper = styled.div`
  display: flex;
  justify-content: center;
  max-width: 900px;
  margin: 5px auto;
  @media (max-width: 790px) {
    flex-direction: column;
  }
`;

export const MethodBlock = styled.div<{ background: string }>`
  width: 50%;
  padding: 30px;
  background: ${({ background }) => background};
  color: white;
  p,
  h2 {
    color: white;
    width: 100%;
  }
  h2 {
    font-size: 23px;
    line-height: 40px;
    margin-bottom: 40px;
    font-family: "NotoSansExtraLight";
    ::after {
      background: white;
      bottom: -25px;
      width: 60px;
    }
  }
  :first-of-type {
    margin-right: 5px;
  }
  @media (max-width: 790px) {
    margin-bottom: 5px;
    width: 100%;
  }
`;

export const OuterWrapper = styled.div`
  display: flex;
  justify-content: center;
  max-width: 900px;
  margin: 10px auto;

  @media (max-width: 790px) {
    flex-direction: column;
  }
`;

export const ThemaBlock = styled.div`
  width: 50%;
  background-color: #e0e0e0;
  font-size: 16px;
  padding: 20px;

  h1 {
    font-size: 34px;
  }

  @media (max-width: 1000px) {
    height: 366px;
  }

  @media (max-width: 790px) {
    height: 100%;
    width: 100%;
  }
`;

const ImageBlock = styled(ThemaBlock)`
  background-size: cover;
  background-position: center;
  @media (max-width: 790px) {
    height: 400px;
  }
`;

export const ThemaBlockImage1 = styled(ImageBlock)`
  background-image: ${`url(${image1})`};
`;

export const ThemaBlockImage2 = styled(ImageBlock)`
  background-image: ${`url(${image2})`};
  transform: scaleX(-1);
`;

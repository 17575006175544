import styled from 'styled-components';

export const PriceWrapper = styled.div`
  margin-top: 30px;

  span {
    font-size: 12px;
  }

  a {
    color: black;
    margin-left: 10px;
  }
`; 
import styled from "styled-components";

export const AppointmentInnerWrapper = styled.div`
  padding: 30px;
  background-color: ${({ theme }) => theme.primary};

  h1 {
    color: #fff;
    ::after {
      background-color: #fff;
    }
  }

  p {
    color: #fff;
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 30px;

  div {
    margin-right: 20px;
    margin-left: 20px;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  margin: 10px 0;
  img {
    width: 30px;
  }
`;

export const Button = styled.a`
  padding: 10px;
  width: 280px;
  height: 40px;
  background: #fff;
  border: 0;
  color: #000;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px;
`;

export const AppointmentWrapper = styled.div`
  ${AppointmentInnerWrapper} {
    min-height: 400px;
    width: 100%;
  }
`;

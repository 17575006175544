import styled from "styled-components"

export const CloseButton = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 20px;
  cursor: pointer;
`

export const Wrapper = styled.nav`
  transition: left 0.6s;
  left: -250px;
  position: fixed;
  top: 0;
  width: 250px;
  background: white;
  height: 100vh;
  z-index: 1;
  border-right: 1px solid grey;

  ul {
    padding-top: 50px;
    flex-direction: column;
  }
`

import React from "react";
import { H1, P, SectionWrapper } from "../SectionStyled";
import { OuterWrapper, ThemaBlock, ThemaBlockImage1 } from "./PraxisStyled";
import Partner from "../Partner/Partner";

const Praxis: React.FC = () => (
  <>
    <SectionWrapper>
      <H1>Praxis</H1>
      <P>
        Die Sitzungen finden in der Psychotherapeutischen Praxis in der
        Sredzkistraße 64 in Prenzlauer Berg statt. Auf Wunsch biete ich auch
        Online-Sitzungen an, Erstgespräche aber in jedem Fall in der Praxis.
      </P>
    </SectionWrapper>
    <OuterWrapper>
      <ThemaBlockImage1 />
      <ThemaBlock>
        <iframe
          title="office location map"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2426.790148747335!2d13.422250000000002!3d52.537230900000004!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47a84e0134364201%3A0xc93d72150f30361e!2sSredzkistra%C3%9Fe%2064%2C%2010405%20Berlin!5e0!3m2!1sen!2sde!4v1686581530782!5m2!1sen!2sde"
          style={{ border: 0 }}
          loading="lazy"
        ></iframe>
      </ThemaBlock>
    </OuterWrapper>
    {/* <Partner /> */}
  </>
);

export default Praxis;

import React from "react";
import { H1, P } from "../SectionStyled";
import {
  AppointmentInnerWrapper,
  AppointmentWrapper,
  ButtonWrapper,
  Button,
  ButtonsWrapper,
} from "./AppointmentStyled";
// @ts-ignore
import email from "../../../images/email.svg";
// @ts-ignore
import phone from "../../../images/phone.svg";

const Appointment: React.FC = () => (
  <AppointmentWrapper>
    <AppointmentInnerWrapper>
      <H1>Kontakt</H1>
      <P>
        Aller Anfang ist leicht. Gerne widme ich mich Ihrem Anliegen und allen
        Fragen in einem Erstgespräch. Vereinbaren Sie hierzu einfach einen
        Termin mit mir.
      </P>
      <P>Vielen Dank für Ihr Vertrauen!</P>
      <ButtonsWrapper>
        <ButtonWrapper>
          <img alt="email icon" src={email} />
          <Button href="mailto:praxis@aufdichhoeren.de">
            praxis@aufdichhoeren.de
          </Button>
        </ButtonWrapper>
        <ButtonWrapper>
          <img alt="phone icon" src={phone} />
          <Button href="tel:+49 178 3143168">+49 178 3143168</Button>
        </ButtonWrapper>
      </ButtonsWrapper>
    </AppointmentInnerWrapper>
  </AppointmentWrapper>
);

export default Appointment;
